import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import * as heroStyles from '../components/hero.module.scss'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulBlogPost')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    const Bold = ({ children }) => <span className="bold">{children}</span>
    const Text = ({ children }) => <p className="align-center">{children}</p>

    const options = {
      renderMark: {
        [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      },
    }

    return (
      <Layout location={this.props.location}>
        <Helmet title={`${post.title} | ${siteTitle}`} />
        {/* <div className={heroStyles.hero}>
          <Img
            className={heroStyles.heroImage}
            alt={post.title}
            fluid={post.heroImage.fluid}
          />
        </div> */}
        <article className="container single-article">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <seciton className="wrapper">
                <p
                  style={{
                    display: 'block',
                    marginBottom: 0,
                  }}
                >
                  {post.publishDate} -{' '}
                  {post.body.childMarkdownRemark.timeToRead} minute read
                </p>
                <h1
                  className="section-headline"
                  style={{ marginBottom: '35px' }}
                >
                  {post.title}
                </h1>
                <div
                  className="post-body"
                  dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                  }}
                />
                {/* <div>
                {documentToReactComponents(
                  JSON.parse(post.body.childrenMarkdownRemark.html),
                  options
                )}
              </div> */}
              </seciton>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      body {
        body
        childMarkdownRemark {
          html
          timeToRead
        }
      }
    }
  }
`
